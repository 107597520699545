<template>
  <div>
    <el-drawer
      :with-header="false"
      :visible.sync="drawer"
      size="1100px"
      :direction="direction"
      :before-close="handleClose"
    >
      <div v-loading="loading">
        <div class="head">
          <div class="full">
            <img class="order_icon" :src="productData.mainImg" alt="" />
            <div class="text">
              <div class="title">{{ productData.name }}</div>
              <div>
                <span class="mr20">商品ID：{{ productData.id }}</span>
              </div>
            </div>
          </div>
          <ul class="list">
            <li class="item">
              <div class="title">商品类型</div>
              <div>{{productData.sourceType == 1 ? '报喜鸟商品' : '供应商' }}</div>
            </li>
            <li class="item">
              <div class="title">商品状态</div>
              <div>{{ productData.status == 1 ? '上架' : '下架' }}</div>
            </li>
            <li class="item">
              <div class="title">销量</div>
              <div>{{ productData.actualSaleNum }}</div>
            </li>
            <li class="item">
              <div class="title">库存</div>
              <div>{{ productData.stock }}</div>
            </li>
            <li class="item">
              <div class="title">创建时间</div>
              <div>{{ productData.createTime }}</div>
            </li>
          </ul>
        </div>
        <el-tabs type="border-card" v-model="activeName" @tab-click="tabClick">
          <el-tab-pane label="基本信息" name="basic">
            <div class="section">
              <!-- <div class="title">用户信息</div> -->
              <ul class="list">
                <li class="item item100">
                  <div class="item-title">封面图：</div>
                  <img :src="productData.mainImg" style="width:40px;height:40px;margin-right:12px;"/>
                </li>
                <li class="item item100" v-if="productData.bannerImg">
                  <div class="item-title">轮播图：</div>
                  <img v-for="(pic,idx) in productData.bannerImg.split(',')" :key="idx" :src="pic" style="width:40px;height:40px;margin-right:12px;"/>
                </li>  
              </ul>
              
              <li class="item item100">
                <div class="item-title">商品简介：</div>
                  <div class="value">{{productData.introduction}}</div>
                </li>
              <ul class="list">
                <li class="item">
                  <div class="item-title">商品来源：</div>
                  <div class="value">{{productData.vendorName}}</div>
                </li>
                <li class="item">
                  <div class="item-title">品牌：</div>
                  <div class="value">{{productData.brandName || '-'}}</div>
                </li>
                <li class="item">
                  <div class="item-title">运费：</div>
                  <div class="value">{{productData.freightType==1?'包邮':productData.freight}}</div>
                </li>
                <li class="item">
                  <div class="item-title">发货说明：</div>
                  <div class="value">{{productData.sendIntroduction}}</div>
                </li>
                <li class="item">
                  <div class="item-title">单位：</div>
                  <div class="value">{{productData.unit}}</div>
                </li>
                <li class="item">
                  <div class="item-title">创建时间：</div>
                  <div class="value">{{productData.createTime}}</div>
                </li>
              </ul>
  
            </div>
          </el-tab-pane>
          <el-tab-pane label="规格与价格" name="goods">
            <div class="section" style="margin-top: 50px">
              <div class="title">规格列表：</div>
              <div class="list">
                <template>
                  <el-table
                    :data="specList"
                    border
                    class="tabNumWidth"
                    size="mini"
                    empty-text="商品规格信息已失效"
                  >
                    <el-table-column
                      v-for="(item, index) in specsList"
                      :key="index"
                      :prop="`specs_${index}`"
                      :label="item.name"
                      width="200px"
                      align="center"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="外部规格编码"
                      min-width="80"
                      prop="outerSkuCode"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="sku图片"
                      min-width="80"
                    >
                      <template slot-scope="scope">
                        <div class="demo-image__preview">
                          <el-image
                            style="width: 60px; height: 60px"
                            :src="scope.row.image"
                          />
                        </div>
                      </template>
                    </el-table-column>
                    <el-table-column
                      align="center"
                      label="库存"
                      min-width="80"
                      prop="stock"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="售价"
                      min-width="80"
                      prop="salePrice"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="体积(m³)"
                      min-width="80"
                      prop="volume"
                    ></el-table-column>
                    <el-table-column
                      align="center"
                      label="重量（KG）"
                      min-width="80"
                      prop="weight"
                    ></el-table-column>
                  </el-table>
                </template>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="商品详情" name="content">
            <div class="section">
              <div class="contentPic" v-html="productData.content"/>
            </div>
          </el-tab-pane>
          <el-tab-pane label="包装清单" name="packageList">
            <div class="section">
              <div class="contentPic" v-html="productData.packageList"/>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </el-drawer>
  </div>
</template>
<script>

import {
  goodsView
} from "@/api/goods/goods";
import { 
  // listSpec,
  listSpecChild,
  goodsSpec,
} from "@/api/supplierGoods/goods";
export default {
  components: {},
  props: {
    drawer: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      productId: "",
      direction: "rtl",
      activeName: "basic",
      productData: {},
      specList: [],
      merParams: [],
      sysParams: [],
      specs: [],
      specsList: [],
      specsArrayBackup:[]
    };
  },


  methods: {
    handleClose() {
      console.log('123')
      this.activeName = 'basic';
      this.$emit('closeDrawer');
    },
    getInfo(id) {
      this.loading = true;
      this.productId = id;
      goodsView(id).then(res => {
        this.loading = false;
        this.productData = res.data
        this.specList = res.data.specDetail
        this.specsArrayBackup = JSON.parse(JSON.stringify( res.data.specDetail));
        this.listSpec(id);
      })
    },
    async getSkuList() {
      // console.log(form.spec)
      // 创建数组array,将form.spec数组和this.specs数组通过id对比,如果相同则将this.specs数组中相同的部分添加到array数组中
      let specArray = [];
      for (const item1 of this.productData.spec) {
        // console.log('item1', item1)
        for (const item2 of this.specs) {
          // console.log('item2', item2)
          if (item1.id === item2.id) {
            item2.checkList = item1.childIds;
            // console.log('checkedList', checkedList)
            item2.checkedList = await this.setCheckedList(
              item1.childIds,
              item2.children
            );
            specArray.push(item2);
          }
        }
      }
      this.specsList = specArray;

      const array =  await this.onloadCartesianProduct();

      this.specList = this.cartesianProductOf(array);


      this.specsArrayBackup.forEach((item1) => {
        this.specList.forEach((item2) => {
          if (
            item1.specIds === item2.specIds ||
            item1.specIds ===
              item2.specIds
                .split(",")
                .sort()
                .join(",")
          ) {
            item2.salePrice = item1.salePrice;
            item2.outerSkuCode = item1.outerSkuCode;
            item2.image = item1.image;
            item2.weight = item1.weight;
            item2.volume = item1.volume;
            item2.stock = item1.stock;
          }
        }); 
      });
      console.log(this.specList, "1111111111");
    },
    // 多规格组合
    onloadCartesianProduct() {
      return new Promise((resolve) => {
        const arr = [];
        this.specsList.map((item) => {
          if (item.checkedList.length > 0) {
            arr.push(item.checkedList);
          }
        });
        resolve(arr);
      });
    },
        // 多规格计算
        cartesianProductOf(array) {
      // console.log('array',array)
      const result = array.reduce(
        function(prev, curr) {
          return prev
            .map(function(x) {
              return curr.map(function(y) {
                return x.concat(y);
              });
            })
            .reduce(function(a, b) {
              return a.concat(b);
            }, []);
        },
        [[]]
      );
      const result2 = result.map((a) => {
        const item = {
          specIds: "",
          name: "",
          ...this.params,
        };
        a.map((b, bi) => {
          if (item.specIds === "") {
            item.specIds = b.id;
          } else {
            item.specIds += "," + b.id;
          }
          item[`specs_${bi}`] = b.name; // 必须 规格项名称

          if (item.name === "") {
            item.name = b.name;
          } else {
            item.name += "," + b.name;
          }
        });
        return item;
      });
      return result2;
    },
    // 获取规格列表
    async listSpec(id) {
      const { data } = await goodsSpec(id);
      for (const item of data) {
        item.checkList = [];
        item.checkedList = [];
        item.children = await this.listSpecChild(item.id);
      }
      this.specs = data || [];

      this.getSkuList();
    },
    // 获取子规格列表
    listSpecChild(e) {
      return new Promise((resolve, reject) => {
        listSpecChild(e)
          .then(({ data }) => {
            const newSpecSon = data.map((item) => {
              const { ...rest } = item;
              return {
                ...rest,
              };
            });
            resolve(newSpecSon);
          })
          .catch(() => {
            reject([]);
          });
      });
    },

    setCheckedList(arr1, arr2) {
      // console.log(arr1,arr2);
      return new Promise((resolve) => {
        const arr = arr1.map((item) => {
          return arr2.find((i) => i.id === item);
        });
        resolve(arr);
      });
    },
    pageChange(page) {
      this.recordForm.page = page
      this.getList()
    },
    handleSizeChange(val) {
      this.recordForm.limit = val
      this.getList()
    },
    tabClick(tab) {
   
    },
  },
};
</script>
<style lang="scss" scoped>
.head {
  padding: 20px 35px;
  .full {
    display: flex;
    align-items: center;
    .order_icon {
      width: 60px;
      height: 60px;
    }
    .iconfont {
      color: var(--prev-color-primary);
      &.sale-after {
        color: #90add5;
      }
    }
    .text {
      align-self: center;
      flex: 1;
      min-width: 0;
      padding-left: 12px;
      font-size: 13px;
      color: #606266;
      .title {
        margin-bottom: 10px;
        font-weight: 500;
        font-size: 16px;
        line-height: 16px;
        font-weight: bold;
        color: #282828;
      }
      .order-num {
        padding-top: 10px;
        white-space: nowrap;
      }
    }
  }
  .list {
    display: flex;
    margin-top: 20px;
    overflow: hidden;
    list-style: none;
    padding: 0;
    .item {
      flex: none;
      width: 20%;
      font-size: 14px;
      line-height: 14px;
      color: rgba(0, 0, 0, 0.85);      
      .title {
        margin-bottom: 12px;
        font-size: 13px;
        line-height: 13px;
        color: #666666;
      }
    }
  }
}
.tabNumWidth{
  max-height: 350px;
  overflow-y: auto;
  &:before{
    display: none;
  }
}
.el-tabs--border-card {
  box-shadow: none;
  border-bottom: none;
}
.section {
  .title {
    margin-bottom: 20px;
    font-size: 14px;
    line-height: 15px;
    color: #303133;
  }
  .list {
    display: flex;
    flex-wrap: wrap;
    list-style: none;
    padding: 0;
    margin: 0;
  }
  .item {
    flex: 0 0 calc(100% / 3);
    display: flex;
    margin-top: 16px;
    font-size: 13px;
    color: #606266;
    align-items: center;
    .item-title{
      width: 100px;
      text-align: right;
      margin: 10px;
      margin-left: 0;
    }
  }
  .item100{
    padding-left: 0;
    flex: 0 0 calc(100% / 1);
    padding-left: 0!important;
  }
  .contentPic{
    width: 500px;
    margin: 0 auto;
    max-height: 600px;
    overflow-y: auto;
    /deep/img{
      max-width: 100%;
    }
  }
  .value {
    // flex: 1;
    .value-item {
      &::after{
        content: "/";
        display: inline-block;
      }
      &:last-child{
        &::after{
          display: none;
        }
      }
    }
    .value-temp{
       &::after{
        content: "、";
        display: inline-block;
      }
      &:last-child{
        &::after{
          display: none;
        }
      }
    }
    image {
      display: inline-block;
      width: 40px;
      height: 40px;
      margin: 0 12px 12px 0;
      vertical-align: middle;
    }
  }
}
.tab {
  display: flex;
  align-items: center;
  .el-image {
    width: 36px;
    height: 36px;
    margin-right: 10px;
  }
}
/deep/.el-drawer__body {
  overflow: auto;
}

/deep/.ones th{
  background: #F0F5FF;
}

</style>
